<template>
    <div class="about_box">
<div class="hxkj-wrap" id="hxkj-wrap">
 
    <div class="hxkj-banner">
        <div class="container">
            <h2>集成电路人才培养和技术服务平台<small>引产入教 提高学生实战能力，技术服务 助推产业创新发展</small></h2>
        </div>
    </div>
    <div class="hxkj-about-us">
        <div class="container">
            <div class="hxkj-about-us-img">
                <div class="hxkj-about-us-bgc"></div>
                <img src="~@/assets/hxkj/hxkj-text-1.svg" alt="慧芯科技">
            </div>
            <p class="hxkj-about-us-text">南京知行慧芯科技有限公司（简称“慧芯科技”）是由中国高等教育和新职业教育领域领军企业慧科集团与国内领先的一站式EDA及相关服务提供商华大九天共同发起成立的集成电路人才培养和技术服务平台，2020年成立于江苏南京。致力于通过打造产业学院、行业认证、赛事、峰会、技术服务“五位一体”的产教融合解决方案，培养我国集成电路的领军人才和产业急需、创新能力强的工程型、技能型人才，推进我国集成电路产业发展。</p>
        </div>
    </div>
    <div class="m-section m-section__jjfa">
        <div class="m-section__wrap container">
            <div class="m-section__title">
                <h3>解决方案<small>“五位一体”的集成电路产教融合解决方案</small></h3>
            </div>
            <div class="m-section__main">
                <div class="m-block-7">
                    <ul class="row-flex">
                        <li class="col-4 col-m-12 m-block-7-item">
                            <div class="title">产业学院</div>
                            <div class="desc">
                                <p>研发领先的集成电路高校人才培养方案，引产入教共建产业学院，可为不同层次的高校搭建/优化专业课程体系，提供学院/专业共建、实验室、训练营等一体化集成电路产教融合人才培养方案。</p>
                            </div>
                            <div class="desc_div first">
                                <div class="desc_tt"><span>产业学院</span></div>
                                <div class="desc_con">
                                    <div class="desc_mm">
                                        <p>研发领先的集成电路高校人才培养方案，引产入教共建产业学院，可为不同层次的高校搭建/优化专业课程体系，提供学院/专业共建、实验室、训练营等一体化集成电路产教融合人才培养方案。</p>
                                    </div>
                                    <p>研发领先的集成电路高校人才培养方案，引产入教共建产业学院，可为不同层次的高校搭建/优化专业课程体系，提供学院/专业共建、实验室、训练营等一体化集成电路产教融合人才培养方案。</p>
                                </div>
                                <img src="~@/assets/hxkj/hxkj-jjfa-1.svg">
                            </div>
                        </li>
                        <li class="col-4 col-m-12 m-block-7-item">
                            <div class="title spec">行业认证</div>
                            <div class="desc spec">
                                <p>紧跟国家职业教育改革政策，联合企校共同打造行业认证标准、培训课程及考试培训中心，学、练、考一体化培养集成电路应用型人才。</p>
                            </div>
                            <div class="desc_div">
                                <div class="desc_tt"><span>行业认证</span></div>
                                <div class="desc_con">
                                    <div class="desc_mm">
                                        <p>紧跟国家职业教育改革政策，联合企校共同打造行业认证标准、培训课程及考试培训中心，学、练、考一体化培养集成电路应用型人才。</p>
                                    </div>
                                    <p>紧跟国家职业教育改革政策，联合企校共同打造行业认证标准、培训课程及考试培训中心，学、练、考一体化培养集成电路应用型人才。</p>
                                </div>
                                <img src="~@/assets/hxkj/hxkj-jjfa-2.svg">
                            </div>
                        </li>
                        <li class="col-4 col-m-12 m-block-7-item">
                            <div class="title">专业赛事</div>
                            <div class="desc">
                                <p>组织面向集成电路教育专项赛事，引入各领域龙头企业深度参与，为高校学生提供赛前辅导、应用实践、作品展示、企业宣讲、人才对接、双创等服务，提升学生专业能力与素养，为产业发现并培养创新人才。</p>
                            </div>
                            <div class="desc_div">
                                <div class="desc_tt"><span>专业赛事</span></div>
                                <div class="desc_con">
                                    <div class="desc_mm">
                                        <p>组织面向集成电路教育专项赛事，引入各领域龙头企业深度参与，为高校学生提供赛前辅导、应用实践、作品展示、企业宣讲、人才对接、双创等服务，提升学生专业能力与素养，为产业发现并培养创新人才。</p>
                                    </div>
                                    <p>组织面向集成电路教育专项赛事，引入各领域龙头企业深度参与，为高校学生提供赛前辅导、应用实践、作品展示、企业宣讲、人才对接、双创等服务，提升学生专业能力与素养，为产业发现并培养创新人才。</p>
                                </div>
                                <img src="~@/assets/hxkj/hxkj-jjfa-3.svg">
                            </div>
                        </li>
                        <li class="col-4 col-m-12 m-block-7-item">
                            <div class="title">峰会论坛</div>
                            <div class="desc">
                                <p>打造集成电路公共服务平台，提供真实企业集成电路平台、提供集成电路实践教学资源、智能教学管理系统以及版图设计服务、PDK开发服务、FPGA开发服务等。</p>
                            </div>
                            <div class="desc_div">
                                <div class="desc_tt"><span>峰会论坛</span></div>
                                <div class="desc_con">
                                    <div class="desc_mm">
                                        <p>打造集成电路公共服务平台，提供真实企业集成电路平台、提供集成电路实践教学资源、智能教学管理系统以及版图设计服务、PDK开发服务、FPGA开发服务等。</p>
                                    </div>
                                    <p>打造集成电路公共服务平台，提供真实企业集成电路平台、提供集成电路实践教学资源、智能教学管理系统以及版图设计服务、PDK开发服务、FPGA开发服务等。</p>
                                </div>
                                <img src="~@/assets/hxkj/hxkj-jjfa-4.svg">
                            </div>
                        </li>
                        <li class="col-4 col-m-12 m-block-7-item">
                            <div class="title spec">技术服务</div>
                            <div class="desc spec">
                                <p>汇聚行业企业代表与学术专家的集成电路高峰论坛，预测行业发展趋势，交流行业发展现状，研讨产教融合实践经验，助力人才培养与行业发展。</p>
                            </div>
                            <div class="desc_div">
                                <div class="desc_tt"><span>技术服务</span></div>
                                <div class="desc_con">
                                    <div class="desc_mm">
                                        <p>汇聚行业企业代表与学术专家的集成电路高峰论坛，预测行业发展趋势，交流行业发展现状，研讨产教融合实践经验，助力人才培养与行业发展。</p>
                                    </div>
                                    <p>汇聚行业企业代表与学术专家的集成电路高峰论坛，预测行业发展趋势，交流行业发展现状，研讨产教融合实践经验，助力人才培养与行业发展。</p>
                                </div>
                                <img src="~@/assets/hxkj/hxkj-jjfa-5.svg">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="m-section m-section__adf7047a">
        <div class="m-section__wrap container">
            <div class="m-section__title">
                <h3>合作案例</h3>
            </div>
            <div class="m-section__main">
                <ul class="m-adf7047a-contents">
                    <li >
                        <div class="m-section__adf7047a-cover"><img src="~@/assets//hxkj/3.jpg" alt=""></div>
                        <div class="m-section__adf7047a-content">
                            <div class="m-section__adf7047a-title">全国大学生集成电路创新创业大赛</div>
                            <div class="m-section__adf7047a-desc">全国大学生集成电路创新创业大赛2020年进入教育部发布的国家级赛事榜单。覆盖30+国内外龙头企业，220+所高校，2000+参赛队，1000+专业教师，50000+学子。</div>
                        </div>
                    </li>
                    <li class="rest">
                        <div class="m-section__adf7047a-cover"><img src="~@/assets//hxkj/2.jpg" alt=""></div>
                        <div class="m-section__adf7047a-content">
                            <div class="m-section__adf7047a-title">“绍兴九天杯”集成电路高峰论坛</div>
                            <div class="m-section__adf7047a-desc">慧芯承办绍兴市“绍兴九天杯”集成电路设计大赛及高峰论坛，面向集成电路优秀的创业团队和龙头企业，为设计企业提供政策、资金、工具、人才、市场宣传等一系列支持，通过峰会平台协助企业获取行业及地方政策，创造融资环境，提升品牌影响力，匹配人才需求，有效对接产业上下游资源，服务区域集成电路产业、技术、人才和资本的对接，支持地方招商引资引智。</div>
                        </div>
                    </li>
                    <li class="rest">
                        <div class="m-section__adf7047a-cover"><img src="~@/assets//hxkj/1.jpg" alt=""></div>
                        <div class="m-section__adf7047a-content">
                            <div class="m-section__adf7047a-title">华南理工大学人才培养</div>
                            <div class="m-section__adf7047a-desc">
                                2020年，慧科与华南理工大学签署合作，共研校企合作课程、共建集成电路专业、校外实训基地等。并于当年9月在广东省工信厅、教育厅指导下，开展“国产EDA进校园”第一站，共建校企联合实验室，举办集成电路模拟IC设计实验实训，吸引50余名微电子相关专业本、硕、博学生参加，并有来自北京大学、电子科技大学等100余位老师、专家观摩了学生线上答辩。</div>
                        </div>
                    </li>
                </ul>
                <ul class="m-adf7047a-navs">
                    <li class="li2">全国大学生集成电路创新创业大赛</li>
                    <li class="li1">“绍兴九天杯”集成电路高峰论坛</li>
                    <li class="li5">华南理工大学人才培养</li>
                </ul>
            </div>
        </div>
    </div>

    <!-- 合作企业 -->
    <div class="m-section m-section__hzhb">
        <div class="m-section__wrap container">
            <div class="m-section__title">
                <h3>高校合作伙伴</h3>
            </div>
            <div class="m-section__main">
                <ul>
                    <li><img src="~@/assets//hxkj/hnlg.png" alt="华南理工大学"></li>
                    <li><img src="~@/assets/hxkj/hbdesf.png" alt="湖北第二师范学院"></li>
                    <li><img src="~@/assets/hxkj/ntdx.png" alt="南通大学"></li>
                    <li><img src="~@/assets/hxkj/czdx.png" alt="常州大学"></li>
                    <li><img src="~@/assets/hxkj/jsdx.png" alt="江苏大学"></li>
                    <li><img src="~@/assets/hxkj/xmdx.png" alt="厦门大学"></li>
                    <li><img src="~@/assets/hxkj/szxxzyjs.png" alt="深圳信息职业技术学院"></li>
                    <li><img src="~@/assets/hxkj/jsxxzyjs.png" alt="江苏信息职业技术学院"></li>
                    <li><img src="~@/assets/hxkj/njxxgc.png" alt="南京信息工程大学"></li>
                    <li><img src="~@/assets/hxkj/gdgy.png" alt="广东工业大学"></li>
                    <li><img src="~@/assets/hxkj/hbjtzyjs.png" alt="河北交通职业技术学院"></li>
                    <li><img src="~@/assets/hxkj/zsdx.png" alt="中山大学"></li>
                </ul>
            </div>
        </div>
    </div>
</div>
    <div class="footer_box">
        <div class="footer_box_safe">
<div class="footer_box_left">
</div>
<div class="footer_box_word">
                            <p>400－6400－770</p>
                            <p>如需合作洽谈，欢迎拨打热线电话或在线留言~</p>
                        </div>
                        <div class="footer_box_right">
                            <span class="footer_ct_rig_btn wantTalkBtnShow">留言</span>
                        </div>
        </div>
    
    </div>

    </div>
</template>
<style lang="scss" scoped>

@import '../../assets/scss/_mixins.scss';

@import '../../assets/scss/_hxkj.scss';
@import '../../assets/scss/common.scss';
@import '../../assets/scss/define.scss';
@import '../../assets/scss/function.scss';
@import '../../assets/scss/template.scss';
@import '../../assets/scss/header.scss';
@import '../../assets/scss/footer.scss';
@import '../../assets/scss/index.scss';

@import '../../assets/scss/module.scss';

.about_box{
    overflow: hidden;
    margin: 0 auto;
    font-family: PingFang-SC-Regular !important;
}
.rest{
    display: none  !important;
}

.hxkj-wrap .m-section__jjfa .m-block-7-item {
    background: #0700FF !important;
}
.hxkj-wrap .hxkj-about-us .hxkj-about-us-img {
        background: url('../../assets/hxkj/left_img.png') top right no-repeat !important;
}
.hxkj-wrap .hxkj-about-us .container .hxkj-about-us-bgc{
    background: #0700FF !important;
}
.footer_box{
    width: 100%;
    background: #0700FF ;

    height: 120px;
    overflow: hidden;
}
.footer_box_left{
    width: 197px;
    height: 64px;
    background: url('../../assets/hxkj/contact-1.svg') no-repeat;
    background-size: 197px 64px;
    margin-top: 28px;
}
.footer_box_safe{
    width: 1168px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}
.footer_box_word{
        flex: 1;
    color: #fff;
    text-align: center;
    margin-top: 28px;
    margin-left: 52px;
}
.footer_box_word p:first-child{
    font-size: 40px;
    line-height: 40px;
}

.footer_box_word p:last-child {
    font-size: 14px;
    line-height: 14px;
    margin-top: 8px;
    }
    .footer_ct_rig_btn {
    background: #fff;
    color: #0663B7;
    height: 50px;
    width: 168px;
    border-radius: 4px;
    margin: 3px 0;
    display: block;
    text-align: center;
    line-height: 50px;
    margin-top: 35px;
    cursor: pointer;
}
.hxkj-wrap .hxkj-banner {
    position: relative;
    height: 640px;
    background: url('../../assets/hxkj/hxkj-topic.jpg') top center no-repeat;
    background-size: cover;
}
</style>
<script>

import  '@/assets/font/PingFang-SC-Regular.css'

export default {
    mounted(){
        var Otab = document.querySelectorAll('.m-adf7047a-contents>li')
        var Oclick = document.querySelectorAll('.m-adf7047a-navs>li')
      
         Oclick[2].addEventListener('mouseover',function(){
            
                Otab[2].classList.remove("rest")
                Otab[1].className+= ' rest';
                Otab[0].className+= ' rest';

          
        })
         Oclick[1].addEventListener('mouseover',function(){
            
                Otab[1].classList.remove("rest")
                Otab[2].className+= ' rest';
                Otab[0].className+= ' rest';

          
        })
         Oclick[0].addEventListener('mouseover',function(){
            
                Otab[0].classList.remove("rest")
                Otab[2].className+= ' rest';
                Otab[1].className+= ' rest';

          
        })
    }
}
</script>